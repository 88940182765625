import axios from '@/apis/axios';
import { getAccessToken, convertCamelToSnake } from '@/lib/api';

/**
 * 代理店一覧取得
 */
export const getAgencies = async (option, condition) => {
  const accessToken = await getAccessToken();
  let params = {};
  if (option) {
    params = Object.assign(params, convertCamelToSnake(option));
  }
  if (condition) {
    params = Object.assign(params, convertCamelToSnake(condition));
  }

  const response = await axios
    .get(`/agencies/agencies`, {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 代理店取得
 */
export const getAgency = async agencyId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/agencies/${agencyId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 代理店登録
 */
export const postAgency = async createParam => {
  const accessToken = await getAccessToken();

  const response = await axios
    .post(
      `/agencies/agencies`,
      { ...createParam },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 代理店一覧更新
 */
export const putAgencies = async updateParam => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/agencies`,
      { ...updateParam },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 代理店更新
 */
export const putAgency = async (agencyId, updateParam) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/agencies/${agencyId}`,
      { ...updateParam },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 代理店削除
 */
export const deleteAgency = async agencyId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .delete(`/agencies/agencies/${agencyId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 代理店ID一覧取得
 */
export const getAgencyIdList = async params => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get('/agencies/agencies/id/list', {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 代理店コード一覧取得
 */
export const getAgencyCodeList = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get('/agencies/agencies/code/list', {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  let list = [];
  response.data.forEach(function(agency) {
    list.push(agency.agencyCode);
  });

  return list;
};

/**
 * 更新対象代理店一覧取得
 */
export const getUpdateAgencyList = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/agencies/update/list`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 代理店一括登録
 */
export const postBulkRegisterAgency = async agencyList => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('agencies', JSON.stringify(agencyList));

  const response = await axios
    .post(`/agencies/agencies/bulk/register`, form, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 代理店一括更新
 */
export const putBulkUpdateAgency = async agencyList => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('agencies', JSON.stringify(agencyList));

  const response = await axios
    .post(`/agencies/agencies/bulk/update`, form, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * SMS/メール利用状況集計取得
 */
export const getCostAggeregate = async condition => {
  const accessToken = await getAccessToken();
  let params = {};
  if (condition) params = Object.assign(params, convertCamelToSnake(condition));

  const response = await axios
    .get(`/agencies/agencies/aggeregate/cost`, {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 代理店情報集計取得
 */
export const getAgencyInformationAggregate = async condition => {
  const accessToken = await getAccessToken();
  let params = {};
  params = Object.assign(params, convertCamelToSnake(condition));

  const response = await axios
    .get(`/agencies/agencies/information/aggregate`, {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
